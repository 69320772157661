
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Banner.css */
.banner {
    display: flex; /* Añadido para asegurar el uso de flexbox */
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    width: 40%;
    margin: 0 auto; /* Centra el banner horizontalmente */
}

.promo-text {
    color: white;
    margin: 200px 0;
    text-align: left; /* Centra el texto */
}

.promo-text h1 {
    font-size: 3rem;
    font-family: "Poppins", sans-serif !important;
    font-weight: 300;
    margin: 0; /* Elimina el margen superior e inferior */
}

.promo-text .offer {
    background-color: #00CFFF;
    color: black;
    padding: 5px;
    display: inline-block;
    margin-top: 10px;
}

.discounts {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en varias líneas */
    gap: 10px;
    justify-content: center; /* Centra los elementos horizontalmente */
}

.quote-button {
    background-color: #FFDD00;
    color: black;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 1rem; /* Asegura que el texto del botón sea legible */
}

/* Estilos responsivos */
@media (max-width: 1024px) {
    .banner {
        width: 60%;
    }

    .promo-text h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .banner {
        width: 80%;
    }

    .promo-text h1 {
        font-size: 2rem;
    }

    .promo-text {
        margin: 100px 0;
    }
    .promo-text img{
        width: 60%;
    }
}

@media (max-width: 480px) {
    .banner {
        width: 100%;
        padding-right: 10px;
    }

    .promo-text h1 {
        font-size: 1.5rem;
    }

    .promo-text {
        margin: 50px 0;
    }

    .quote-button {
        padding: 10px;
        font-size: 0.9rem;
    }
}


