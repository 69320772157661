@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.main-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    height: 100vh;
}

.main-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://www.prosegur.cl/dam/jcr:f1d0ecc0-3457-4e55-987d-87883224d913/hero1.webp');
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.content-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    max-height: 800px;
}

/*Responsive*/
@media screen and (max-width: 1024px) {
    .main-section {
        padding: 40px;
        height: auto; /* Permite que la altura se ajuste automáticamente */
    }

    .content-container {
        padding: 15px;
    }

    .content-container .left-content, .content-container .right-content {
        flex: 1;
    }

    .content-container .left-content img {
        width: 100%;
        height: auto; /* Ajusta automáticamente la altura */
    }

    .content-container .right-content h1 {
        font-size: 2rem;
    }

    .content-container .right-content p {
        font-size: 1.2rem;
    }

    .content-container .right-content button {
        padding: 0.75rem 2.5rem;
    }
}

@media screen and (max-width: 768px) {
    .main-section {
        padding: 30px;
    }

    .content-container {
        flex-direction: column;
        align-items: center; /* Centra el contenido horizontalmente */
    }

    .content-container .left-content {
        margin-bottom: 20px;
        text-align: center; /* Centra el texto */
    }

    .content-container .right-content {
        margin-top: 20px;
        text-align: center; /* Centra el texto */
    }

    .content-container .left-content img, .content-container .right-content img {
        width: 100%;
        height: auto; /* Ajusta automáticamente la altura */
    }

    .content-container .right-content h1 {
        font-size: 1.5rem;
    }

    .content-container .right-content p {
        font-size: 1rem;
    }

    .content-container .right-content button {
        padding: 0.5rem 2rem;
    }
}

@media screen and (max-width: 480px) {
    .main-section {
        padding: 20px;
    }

    .content-container {
        padding: 10px;
    }

    .content-container .right-content h1 {
        font-size: 1.2rem;
    }

    .content-container .right-content p {
        font-size: 0.9rem;
    }

    .content-container .right-content button {
        padding: 0.5rem 1.5rem;
    }
}
