@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Form.css */
.form-container {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    width: 450px;
    border-radius: 8px; /* Añadido para dar un borde redondeado */
    margin: 20px auto; /* Centra el contenedor del formulario */
}

.form-container h2 {
    margin-top: 1rem;
    font-size: 22px;
}

.form-container h2 span {
    font-size: 30px;
    color: #ffd000;
}

form {
    display: flex;
    flex-direction: column;
}

form input[type="text"], form input[type="email"], form input[type="password"], form input[type="number"], form input[type="date"], form select {
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #4c4c4c;
    font-weight: 400;
    color: #4c4c4c;
    font-size: 1.125rem;
    height: 3rem;
    padding: 10px;
    margin: 15px 0;
    width: 100%;
}

form button {
    background-color: #ffd000 !important;
    border-color: #ffd000 !important;
    color: #000 !important;
    font-weight: 700;
    font-size: 1rem;
    padding: 1rem 3rem;
    box-shadow: none;
    border-radius: 6rem;
    border-width: .125rem;
    border-style: solid;
    opacity: 1 !important;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

form button:hover {
    background-color: #4c4c4c !important;
    border-color: #4c4c4c !important;
    color: #fff !important;
    cursor: pointer;
}

.form-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
}

.form-group label {
    font-size: 13px;
    font-weight: 400;
    /* Alina el texto con el input */
    align-self: center;
    margin-bottom: 30px;
}

.error {
    color: red;
    font-size: 0.8em;
    margin-top: 0.25em;
    display: block;
    width: 100%;
}

.politicas {
    color: #ffd000;
    text-decoration: none;
    font-weight: 600;
}

.politicas:hover {
    color: #ffffff;
    text-decoration: underline;
}
.btn-back{
    background-color: #ffd000 !important;
    border-color: #ffd000 !important;
    color: #000 !important;
    font-weight: 700;
    font-size: 1rem;
    padding: 1rem 3rem;
    box-shadow: none;
    border-radius: 6rem;
    border-width: .125rem;
    border-style: solid;
    opacity: 1 !important;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    margin-top: 20px;
}

.btn-back:hover {
    background-color: #4c4c4c !important;
    border-color: #4c4c4c !important;
    color: #fff !important;
    cursor: pointer;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .form-container {
        width: 100%;
        padding: 15px;
    }

    form button {
        padding: 1rem 2rem;
    }

    form input {
        font-size: 1rem;
    }

    form input, form button {
        margin: 10px 0;
    }

    form button {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .form-container {
        width: 100%;
        padding: 10px;
    }

    form input {
        font-size: 0.875rem;
    }

    form button {
        padding: 0.75rem 1.5rem;
        font-size: 0.875rem;
    }

    .form-group label {
        font-size: 12px;
        margin-bottom: 20px;
    }
}

