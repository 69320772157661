/* Header.css */
.header {
    background-color: #FFD000;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000; /* Añadido para asegurar que el header esté por encima de otros elementos */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Añadido para dar un efecto de sombra */
}

.logo {
    height: 30px;
}

/* Responsive */
@media screen and (max-width: 768px) {
    .logo {
        height: 25px;
    }

    .header {
        padding: 8px;
        z-index: 1000; /* Asegura que el header siga estando por encima */
    }
}

@media screen and (max-width: 480px) {
    .logo {
        height: 20px;
    }

    .header {
        padding: 5px;
        z-index: 1000; /* Asegura que el header siga estando por encima */
    }
}


